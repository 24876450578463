.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.lightblue {
    color: var(--light-blue);
    font-size: xx-large;
}