.Footer {
    background-color: var(--light-blue);
    height: 250px;
}

.FooterColumns {
    display: flex;
    text-shadow: 2px 2px 8px #151515;
    font-size: large;
    height: 200px;
}

.FooterCopyright {
    display: flex;
    height: 50px;
    text-shadow: 2px 2px 8px #151515;
    font-size: large;
}

.FooterLogo {
    width: 100%;
}

.FooterColumn1 {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.FooterColumn2 {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 2px solid var(--white);
}
.FooterColumnEmpty {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 2px solid var(--white);
}
.FooterColumn3 {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 2px solid var(--white);
}
.FooterColumn3 p,
.FooterColumn4 p {
    margin-top: 0px;
}
.FooterColumn4 p img{
    padding-left: 10px;
}
.FooterColumn4 {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 2px solid var(--white);
}
.FooterColumn5 {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.FooterCopyright1 {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.FooterCopyright2 {
    flex: 2 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.FooterCopyright3 {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.FooterCopyright4 {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
}
.FooterCopyright5 {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
