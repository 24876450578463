.MeetingImage {
  position: relative;
  display: inline-block;
  width: 80%;
}

.MeetingImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(37, 32, 32, 0.145) 40%, var(--navy-blue) 80%);
}

.MeetingImage img {
  display: block;
  width: 90%;
}

.LightblueBox {
  background-color: var(--light-blue);
  width: 100%;
  height: 40px;
}

.WhiteBox {
  background-color: var(--white);
  width: 100%;
  height: 40px;
}

.About {
  display: flex;
  align-items: center;
}

.AboutText {
  font-size: 16pt;
  width: 30%;
  padding-right: 50px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}