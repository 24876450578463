.Header {
    background-color: #11101e;
    padding-block: 15px;
    border: 25px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.App-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Logo {
    width: 15%;
}

.MenuList {
    margin-right: 20px;
    display: flex;
}

.MenuList li {
    cursor: default;
    margin-inline: 20px;
    list-style-type: none;
    font-weight: 600;
    transition: transform 0.3s ease;
}

.MenuList li:hover {
    transform: scale(1.1);
  }