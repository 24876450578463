.CodeEditor {
    height: 580px;
    width: 100%;
    background-color: var(--white);
}

.CodeEditorText {
  width: 100%;
  color: #181616;
  display: flex;
  justify-content: flex-end;
  padding-inline: 40px;
  font-size: 16pt;
  align-items: center;
}

.CodeEditorWrapper {
    display: flex;
    flex-direction: row;
    padding: 50px;
}

.Editor {
    flex: 1;
    font-family: monospace;
    white-space: pre-wrap;
    background-color: #212020;
    min-width: 400px;
    height: 400px;
    padding: 20px;
    line-height: 1.6;
    animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;
}

.LineNumberContainer {
    width: 30px;
    background-color: #181616;
    padding: 20px;
    font-family: monospace;
    line-height: 1.6;
}

.LineNumberContainer span {
    display: block;
  }

.LightblueBox {
  background-color: var(--light-blue);
  width: 100%;
  height: 40px;
}
