@import url('https://fonts.cdnfonts.com/css/gotham');

:root {
    --navy-blue: #11101e;
    --light-blue: #5fc4e2;
    --white: whitesmoke;
    width: 100%;
}

body {
    background-color: #11101e;
    color: #ffffff;
    font-family: 'Gotham', sans-serif;
    margin: 0;
}