.LoadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #11101e;
    z-index: 9999;

    opacity: 1;
    transition: opacity 0.5s ease-out;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.LoadingOverlay.hidden {
    opacity: 0;
    pointer-events: none; 
}

.LoadingContent {
    z-index: 10000;
    height: 100%;
    display: contents;
}
  
.CoverLogo {
    width: 30%;
}