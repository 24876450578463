.TechnologyStack {
    display: flex;
    justify-content: center;
    height: 600px;
}

.TechnologyStackDescription1,
.TechnologyStackDescription2 {
    width: 20%;
    display: flex;
    align-items: center;
    padding: 5%;
    font-size: 16pt;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.TechnologyStackWrapper {
    display: flex;
    --s: 100px; /* Use viewport width unit for hexagon size */
    --m: 1vw; /* Use viewport width unit for margin */
    --f: calc(1.732 * var(--s) + 4 * var(--m) - 1px);
    height: 40vh; /* Use viewport height unit for height */
    width: 40%; /* Use percentage for width */
    flex-wrap: wrap;
    justify-content: center;
}

.Hexagon,
.HexagonSmaller,
.HexagonHide {
    width: var(--s);
    margin: var(--m);
    height: calc(var(--s) * 1.1547);
    display: inline-block;
    font-size: initial;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    margin-bottom: calc(var(--m) - var(--s) * 0.2885);
    transition: transform 0.3s ease;
    background: rgba(95, 196, 226, 0.88);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.3px);
    -webkit-backdrop-filter: blur(1.3px);
    border: 1px solid rgba(95, 196, 226, 0.11);
}

.HexagonHide {
    background: transparent;
    border: 0px;
}

.Hexagon img,
.HexagonSmaller img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    max-width: 100%;
    max-height: 100%;
}

.HexagonSmaller img {
    transform: translate(-50%, -50%) scale(1.2);
}

.HexagonSmaller:hover,
.Hexagon:hover {
    transform: scale(0.9);
}

a .HexagonSmaller,
a .Hexagon {
    cursor: default;
}

.TechnologyInsideWrapper::before {
    content: "";
    width: calc(var(--s) / 2 + var(--m));
    float: left;
    height: 120%;
    shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f) - 3px), #000 0 var(--f));
}
