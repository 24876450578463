.Intro {
    height: 600px;
    width: 100%;
    /*background-color: whitesmoke;*/
    /* background: linear-gradient(to right, var(--white), var(--navy-blue)); */
    margin-top: 80px;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.IntroFirst {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 35%;
    align-items: flex-end;
}

.IntroSecond {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 30%;
    align-items: center;
}

.IntroThird {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 35%;
    align-items: flex-start;
}

.Column {
    flex: 1;
    display: flex;
    justify-content: center;
  }